main,
aside.sidebar {
  margin: 2em 0;
  font-size: 1.2rem;
  @media(min-width: breakpoint-min(sm)) {
    padding-right: 0;
    font-size: 1rem;
  }
}

.img-responsive {
  width: 100%;
}

.main {
  padding: 0 15px;
  min-height: 600px;
  @media(min-width: breakpoint-min(md)) {
    padding: 0 15px 0 0;
  }
  .featured-image {
    margin-top: -32px;
    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
    }
  }

  .container {
    padding-left: 0px !important;
  }
}

#breadcrumbs {
  margin-top: -1.3em;
  font-size: 1em;
  color: #333;
  a {
    color: $color-primary;
  }
}

.container-wrapper {
  padding: 3em 0;
  &.gray {
    background: #f7f7f7;
  }
  &.no-padding {
    padding: 0;
  }
}

.page-header h1 {
  padding-top: 1rem;
  margin-bottom: 0.5em;
}

.sidebar-custom {
  background-color: #f7f7f7;
  padding: 1.5em;
}

.search-form {
  label {
    flex: 1;
    margin: 0;
  }
  .search-field {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    width: 100%;
  }
  input.search-submit {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.tribe-events-calendar td div[id*=tribe-events-daynum-],
.tribe-events-calendar td div[id*=tribe-events-daynum-] a {
  margin: 0 !important;
}

//Delete Later - for Presentation Only
#links {
  img {
    width: 100%;
  }
  .row>div {
    margin-bottom: 1em;
  }
}

.btn {
  background: #333;
  color: #FFF;
}

/*
Events - Event Calendar Pro
 */

#tribe-events .tribe-events-button,
#tribe-events .tribe-events-button:hover,
#tribe_events_filters_wrapper input[type=submit],
.tribe-events-button,
.tribe-events-button.tribe-active:hover,
.tribe-events-button.tribe-inactive,
.tribe-events-button:hover,
.tribe-events-calendar td.tribe-events-present div[id*=tribe-events-daynum-],
.tribe-events-calendar td.tribe-events-present div[id*=tribe-events-daynum-]>a {
  background-color: #af0028;
}

.datepicker.dropdown-menu {
  z-index: 100 !important;
}

.tribe-events-gmap {
  background: $color-primary;
  color: #FFF;
  white-space: normal;
  padding: 5px 5px;
  text-decoration: none;
  display: inline-block;
  border: none;
  font-style: normal;
  border-radius: 3px;
  text-transform: uppercase;
  margin-left: 5px;
  font-weight: 800;
  font-size: 0.75em;
  letter-spacing: 1px;
}

.tribe-events-calendar td.tribe-events-past div[id*=tribe-events-daynum-],
.tribe-events-calendar td.tribe-events-past div[id*=tribe-events-daynum-]>a {
  background-color: #444;
  color: #fff;
}

.tribe-events-calendar thead th {
  background-color: $color-primary;
  color: #fff;
  font-weight: 700;
  padding: 1em 0;
  white-space: nowrap;
  border-left: 1px solid #333;
  border-right: 1px solid #a20d30;
}

.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

#tribe-events-content .tribe-events-calendar td {
  border: 1px solid #4e4e4e;
  border-top: 0;
  -webkit-transition: all .2s cubic-bezier(.55, .085, .68, .53);
  transition: all .2s cubic-bezier(.55, .085, .68, .53);
}

#tribe-bar-form .tribe-bar-submit input[type=submit] {
  background: $color-primary;
}

.tribe-events-day .tribe-events-day-time-slot h5 {
  background: #444;
}

.tribe-events-list-widget .tribe-list-widget {
  padding-left: 0;
  >li {
    padding: 0;
  }
  >li:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.tribe-events-widget-link a {
  @extend .btn;
  @extend .btn-primary;
}

//Home
.home main {
  margin: 0;
  padding: 0;
}

.header-banner {
  color: #FFF;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    text-shadow: 2px 2px 6px rgba(0, 0, 0, .6);
  }
  h2 {
    font-size: 3rem;
    margin-bottom: 15px;
    margin-top: 0;
    font-weight: 100;
    @media(min-width: breakpoint-min(sm)) {
      font-size: 4em;
    }
  }
  h4 {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 100;
    @media(min-width: breakpoint-min(sm)) {
      font-size: 2.5em;
    }
  }
  p {
    margin-top: 10px;
    line-height: 1.6;
    margin-bottom: 30px;
    font-weight: 100;
  }
  .btn {
    font-size: 1.2em;
  }
}

.cta-wrapper {
  padding: 2em 0;
  background: #333;
  color: #FFFF;
  h4 {
    margin: 0;
    font-weight: 100;
    line-height: 1.8;
  }
  .align-items-center>div {
    text-align: center;
    @media(min-width: breakpoint-min(sm)) {
      text-align: left;
    }
  }
  .align-items-center>div+div {
    @media(min-width: breakpoint-min(sm)) {
      text-align: right;
    }
  }
}

.recent-news {
  padding: 1em 0;
}

.recent-news img {
  width: 100%;
  height: auto;
}

.recent-news .news-item {
  margin-bottom: 30px;
}

.site-types {
  h2 {
    margin-top: 0;
    margin-bottom: 1em;
  }
}

.tab-content {
  overflow: hidden;
  border-radius: 8px;
  background: #333;
  box-shadow: 0 10px 40px 0 rgba(112, 128, 175, 0.38), 0 8px 0 0 rgba(103, 117, 161, 0.06);
  margin-top: 1em;
}

.tab-pane>.row {
  .col-md-6 {
    min-height: 500px;
  }
  >.col-md-6:nth-child(2) {
    background: rgba(0, 0, 0, 0.6);
    color: #FFF;
    h3,
    p {
      text-shadow: $text-shadow;
      font-weight: 100;
      line-height: 1.7;
    }
    h3 {
      font-weight: 300;
    }
    div {
      padding: 0 1em;
    }
  }
}

//Feature List
.feature {
  margin: 1em 0;
}

//Flexible Fields
//Image-Text
.flexfield__imagetext {
  padding: 0 0 2em;
  .d-flex .col:first-child {
    padding-left: 0;
  }
}

.tab-content {
  .tab-pane .row {
    >div:first-child {
      display: none;
      @media(min-width: breakpoint-min(md)) {
        display: flex;
      }
    }
  }
}

.main .featured-image {
  margin-top: 0;
}
.home .main .featured-image,.page .main .featured-image {
  margin-top: -32px;
}